import { BANNER } from "./action";

const globalState = {
  banner: 0,
};

const global = (state = globalState, action) => {
  if (action.type === BANNER.type) {
    return action.payload;
  } else {
    return state;
  }
};

export default global;

import "./App.scss";
import Nav from "../src/components/Nav";
import Footer from "../src/components/footer";
import { HashRouter, Route, Switch, BrowserRouter } from "react-router-dom";
import React, { Suspense } from "react";
import { useMedia } from "react-use";

import loadable from "./utils/loadable";
const Home = loadable(() => import("./pages/home"));

const Layout = props => (
  <div className="App">
    <Nav />
    <div className="content">
      {props.children}
    </div>
    <Footer />
  </div>
);

const App = () => {
  const below960 = useMedia("(max-width: 960px)");
  return (
    <Suspense fallback={<div>loading....</div>}>
      <BrowserRouter>
        <Switch>
          <Route path="/" component={Layout}>
            <Layout>
              <Route exact path="/" component={Home} />
            </Layout>
          </Route>
        </Switch>
      </BrowserRouter>
    </Suspense>
  );
};

export default App;

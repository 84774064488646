export function shortenAddress(address, chars = 6) {
  return address
    ? `${address.substring(0, chars)}...${address.substring(
      address.length - chars
    )}`
    : "";
}

// 截取小数
export const decimalBit = (num, count = 2) => {
  if (isNaN(num) && num) {
    return num;
  }
  if (!num || num == " " || num == 0) {
    if (count == 0) {
      return 0;
    }
    let fillZero = "";
    for (let i = 0; i < count; i++) {
      fillZero += "0";
    }
    return `0.${fillZero}`;
  }

  const numList = String(num).split(".");
  if (count === 0) {
    return numList[0];
  }
  if (numList.length > 1) {
    const decimalAfterQuantity = `${String(numList[1]).substr(0, count)}`;
    let fillZero = "";
    const fillZeroQuantity = count - decimalAfterQuantity.length;
    if (fillZeroQuantity > 0) {
      for (let i = 0; i < fillZeroQuantity; i++) {
        fillZero += "0";
      }
    }
    return `${numList[0]}.${decimalAfterQuantity}${fillZero}`;
  } else {
    let fillZero = "";
    for (let i = 0; i < count; i++) {
      fillZero += "0";
    }
    return `${num}.${fillZero}`;
  }
};

// 获取单位
export const getUnit = decimal => {
  const wei =
    decimal == 3
      ? "kwei"
      : decimal == 6
        ? "mwei"
        : decimal == 9
          ? "gwei"
          : decimal == 12
            ? "szabo"
            : decimal == 15
              ? "finney"
              : decimal == 18
                ? "ether"
                : "ether";
  return wei;
};


export function moverDom(domId) {

  // window.location.hash = domId
  // return
  let dom = document.getElementById(domId)
  dom.scrollIntoView({
    behavior: "smooth",
    block: "start",
  })
}

export function shortNotation(value, startFrom) {

}

var SI_SYMBOL = ["", "K", "M", "B", "T", "Q"];

export function abbreviateNumber(number, limit) {

  // what tier? (determines SI symbol)
  var tier = Math.log10(Math.abs(number)) / 3 | 0;

  // if zero, we don't need a suffix
  if (tier == 0) return number;

  // get suffix and determine scale
  var suffix = SI_SYMBOL[tier];
  var scale = Math.pow(10, tier * 3);

  // scale the number
  var scaled = number / scale;

  // format number and add suffix
  return scaled.toFixed(limit) + suffix;
}

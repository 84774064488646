import React, { useState, useEffect, useCallback } from "react";
import Down from "../../assets/img/select-arrow.png";
import DownMove from "../../assets/img/select-arrow-move.png";
import EarthLang from "../../assets/img/earth-lang.png";

import i18next from "i18next";
import { useMedia } from "react-use";
import "./index.scss";

const LangList = [
  {
    lang: "English",
    key: "EN"
  },
  {
    lang: "中文",
    key: "ZH"
  },
  // {
  //   lang: "한국어",
  //   key: "KO"
  // },
  /*{
    lang: "русский",
    key: "RU"
  },
  {
    lang: "Tiếng Việt",
    key: "TH"
  },
  {
    lang: "ไทย",
    key: "VI"
  },
  {
    lang: "日本語",
    key: "JA"
  },
  {
    lang: "Italiano",
    key: "IT"
  }*/
];

const SelectLang = props => {
  const { isShadow } = props;
  const [selectLang, setSelectLang] = useState(false);
  const [langText, setLangText] = useState("");
  const [languageKey, setLanguageKey] = useState("")
  const below960 = useMedia("(max-width: 960px)");

  var scrollFunc = function (e) {
    if (selectLang){
      setSelectLang(false)
    }
  }
  
  window.addEventListener("DOMMouseScroll", scrollFunc) // 给页面绑定鼠标滚轮事件，针对Google，mousewheel非标准事件已被弃用，请使用 wheel事件代替
  window.addEventListener("wheel", scrollFunc)   // ie不支持wheel事件，若一定要兼容，可使用mousewheel
  window.addEventListener("mousewheel", scrollFunc)

  const setLang = () => {
    const language = localStorage.getItem("i18nextLng");
    setLanguageKey(language)
    if (language === "EN") {
      setLangText(below960 ? "English" : "English");
    }
    if (language === "ZH" || language === "zh-CN" || language === "zh") {
      setLangText(below960 ? "中文" : "中文");
    }
    if (language === "RU") {
      setLangText(below960 ? "RU" : "русский");
    }
    if (language === "KO") {
      setLangText(below960 ? "KO" : "한국어");
    }
    if (language === "TH") {
      setLangText(below960 ? "TH" : "Tiếng Việt");
    }
    if (language === "VI") {
      setLangText(below960 ? "VI" : "ไทย");
    }
    if (language === "JA") {
      setLangText(below960 ? "JA" : "日本語");
    }
    if (language === "IT") {
      setLangText(below960 ? "IT" : "Italiano");
    }
  };

  useEffect(() => {
    setLang();
  }, []);

  const toogleLang = useCallback((e, lang) => {
    /**修改语言 */
    e.stopPropagation();
    i18next.changeLanguage(lang, () => { });
    setSelectLang(false);
    setLang();
  }, []);
  const close = e => {
    e.stopPropagation();
    setSelectLang(false);
  };
  return (
    <div
      className={["select_lang", isShadow && "select_lang_move"].join(" ")}
      onClick={() => setSelectLang(true)}
    >
      <div className="lang_text">
        {/* <span>{langText}</span>
        <img src={isShadow ? DownMove : Down} alt="" className={selectLang ? "select_lang_icon active" : "select_lang_icon"} /> */}
        {
          below960 ? langText :
            <img src={EarthLang} alt="" className="earth_lang" />
        }
      </div>
      {selectLang ? (
        <>
          <div className="select_lang_mask" onClick={e => close(e)} />
          <div className={["select_lang_menu", "toggle"].join(" ")}>
            {LangList.map(item => (
              <div
                className={["select_lang_menu_item", item.key == languageKey ?"selected_lang_menu_item":""].join(" ")}
                key={item.key}
                onClick={e => toogleLang(e, item.key)}
              >
                {item.lang}
              </div>
            ))}
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default SelectLang;

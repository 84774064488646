import React, { useEffect, useState } from "react";
import { useMedia } from "react-use";
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import SelectLang from '../../components/SelectLang'
import "./index.scss";
import { message } from 'antd'
import Logo from '../../assets/img/logo.png'
import NavOn from '../../assets/img/nav_on.png'
import NavOnOnly from '../../assets/img/nav_on_only.png'
import Menu from '../../assets/img/menu.png'
import CloseIcon from '../../assets/img/close_menu.png'
import { moverDom } from '../../utils'
import config from "../../config";
import { useDispatch } from "react-redux";

const Nav = props => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch()
  const history = useHistory()
  const [isMoreBox, setMoreBox] = useState(false)
  const [moreBoxTab, setMoreBoxTab] = useState(0)
  const below960 = useMedia("(max-width: 960px)");
  const [top0, setTop0] = useState(true)
  const [footerTab, setFooterTab] = useState(0)
  const [isScrollTop, setScrollTop] = useState(false)

  const navGoTo = h => {
    history.push(h);
    document.body.scrollTop = document.documentElement.scrollTop = 0
    setMoreBox(false)
  };

  const open = (p) => {
    window.open(p)
  }


  const comingsoon = () => {
    message.info(t('globle_敬请期待2'))
  }

  var scrollFunc = function (e) {
    var e = e || window.event;
    if (e.wheelDelta) {
      if (e.wheelDelta > 0) { //当鼠标滚轮向上滚动时
        const top = window.pageYOffset
        if (top == 0) {
          setTop0(true)
          setScrollTop(false)
          return
        }
        setTop0(false)
        if (isScrollTop) {
          return
        }
        setScrollTop(true)
      }
      if (e.wheelDelta < 0) { //当鼠标滚轮向下滚动时
        if (!isScrollTop) {
          return
        }
        setScrollTop(false)
      }
    }
  }
  let startx, starty;

  function getAngle(angx, angy) {
    return Math.atan2(angy, angx) * 180 / Math.PI;
  };
  //根据起点终点返回方向 1向上 2向下 3向左 4向右 0未滑动
  function getDirection(startx, starty, endx, endy) {
    var angx = endx - startx;
    var angy = endy - starty;
    var result = 0;

    //如果滑动距离太短
    if (Math.abs(angx) < 2 && Math.abs(angy) < 2) {
      return result;
    }

    var angle = getAngle(angx, angy);
    if (angle >= -135 && angle <= -45) {
      result = 1;
    } else if (angle > 45 && angle < 135) {
      result = 2;
    } else if ((angle >= 135 && angle <= 180) || (angle >= -180 && angle < -135)) {
      result = 3;
    } else if (angle >= -45 && angle <= 45) {
      result = 4;
    }

    return result;
  }
  //手指接触屏幕
  document.addEventListener("touchstart", function (e) {
    startx = e.touches[0].pageX;
    starty = e.touches[0].pageY;

  }, false);
  document.addEventListener("touchmove", function (e) {
    console.log(e)

    var endx, endy;
    endx = e.changedTouches[0].pageX;
    endy = e.changedTouches[0].pageY;

    var direction = getDirection(startx, starty, endx, endy);
    if (direction == 1) {


      if (!isScrollTop) {
        return
      }
      setScrollTop(false)
    }
    if (direction == 2) {

      const top = window.pageYOffset
      if (top == 0) {
        setTop0(true)
        setScrollTop(false)
        return
      }
      setTop0(false)
      if (isScrollTop) {
        return
      }
      setScrollTop(true)
    }
  }, false);

  window.addEventListener("DOMMouseScroll", scrollFunc) // 给页面绑定鼠标滚轮事件，针对Google，mousewheel非标准事件已被弃用，请使用 wheel事件代替
  window.addEventListener("wheel", scrollFunc)   // ie不支持wheel事件，若一定要兼容，可使用mousewheel
  window.addEventListener("mousewheel", scrollFunc)

  const mout = (e) => {
    // e.stopPropagation()
    setMoreBoxTab(0)
    setMoreBox(false)
  }

  return <div className={["nav_box", (isScrollTop || isMoreBox) && "nav_box_move", top0 && 'nav_box_move_top'].join(' ')}>
    {/* <div onClick={() => dispatch({ type: 'banner', payload: { banner: 0 } })}>123</div>
    <div onClick={() => dispatch({ type: 'banner', payload: { banner: 1 } })}>123</div>
    <div onClick={() => dispatch({ type: 'banner', payload: { banner: 2 } })}>123</div> */}
    {
      // isMoreBox ?
      <div className={["nav_more_box", isMoreBox ? 'nav_more_box_block' : ''].join(' ')}>

        <div className="nav_more_content"
          onMouseLeave={(e) => {
            mout(e)
          }}
        >

          {
            below960 ?
              <div className="nav_more_box_header">
                <img src={Logo} className="header_logo" />
                <img src={CloseIcon} className="header_close" onClick={() => setMoreBox(false)} />
              </div> : ''
          }
          {
            !below960 ?

              <div className="nav_more_content_min">
                {
                  moreBoxTab == 1 ?
                    <div className="nav_more_content_min_c">
                      <div className="nav_more_content_min_c_item"
                        onClick={() => open(config.dappUrl + "coinInsurancelist")}
                      >
                        {t('globle_币价保险')}
                      </div>
                      <div className="nav_more_content_min_c_item nav_more_content_min_c_item_act"
                        onClick={() => open(config.dappUrl + "insurance")}>
                        {t('globle_合约保险')}
                      </div>
                      <div className="nav_more_content_min_c_item"
                        onClick={comingsoon}>
                        {t('globle_NFT保险')}
                        <span>
                          {t('globle_敬请期待')}
                        </span>
                      </div>
                      <div className="nav_more_content_min_c_item"
                        onClick={comingsoon}>
                        {t('globle_意外事件保险')}
                        <span>{t('globle_敬请期待')}</span>
                      </div>
                      <div className="nav_more_content_min_c_item"
                        onClick={comingsoon}>
                        {t('globle_传统金融保险')}
                        <span>{t('globle_敬请期待')}</span>
                      </div>
                    </div> :
                    <div className="nav_more_content_min_c">
                      <div className="nav_more_content_min_c_item"
                        onClick={() =>
                          // open(config.dappUrl + "coinInsurance/pool")
                          moverDom('earn')
                        }>
                        {t('globle_赚保险费')}
                      </div>
                      <div className="nav_more_content_min_c_item nav_more_content_min_c_item_act"
                        onClick={() =>
                          // open(config.dappUrl + "insurance/earn")
                          moverDom('earn')
                        }>
                        {t('globle_质押理财')}
                      </div>
                      <div className="nav_more_content_min_c_item"
                        onClick={() =>

                          moverDom('earn')
                          // open(config.dappUrl + "bond")
                        }>
                        {t('globle_债券机制')}
                      </div>
                    </div>
                }

              </div> :
              <div className="nav_more_content_min">

                <div className="nav_more_content_min_head" onClick={() => setFooterTab(footerTab == 1 ? 0 : 1)}>
                  <div className={["nav_more_content_min_head_down", footerTab == 1 ? 'nav_more_content_min_head_down_act' : ''].join(' ')} />
                  {t('globle_产品')}</div>
                {
                  footerTab == 1 ?
                    <>
                      <div className="nav_more_content_min_item"
                        onClick={() => open(config.dappUrl + "coinInsurancelist")}>{t('globle_币价保险')}</div>
                      <div className="nav_more_content_min_item"
                        onClick={() => open(config.dappUrl + "insurance")}>{t('globle_合约保险')}</div>
                      <div className="nav_more_content_min_item"
                        onClick={comingsoon}>{t('globle_NFT保险')} <span>{t('globle_敬请期待')}</span></div>
                      <div className="nav_more_content_min_item"
                        onClick={comingsoon}>{t('globle_意外事件保险')} <span>{t('globle_敬请期待')}</span></div>
                      <div className="nav_more_content_min_item"
                        onClick={comingsoon}>{t('globle_传统金融保险')} <span>{t('globle_敬请期待')}</span></div>
                    </> : ''}
                <div className="nav_more_content_min_head" onClick={() => setFooterTab(footerTab == 2 ? 0 : 2)}>
                  <div className={["nav_more_content_min_head_down", footerTab == 2 ? 'nav_more_content_min_head_down_act' : ''].join(' ')} />
                  {t('globle_理财')}</div>
                {
                  footerTab == 2 ?
                    <>
                      <div className="nav_more_content_min_item"
                        onClick={() =>
                          moverDom('earn')
                          // open(config.dappUrl + "coinInsurance/pool")
                        }>
                        {t('globle_赚保险费')}
                      </div>
                      <div className="nav_more_content_min_item"
                        onClick={() =>
                          moverDom('earn')
                          // open(config.dappUrl + "insurance/earn")
                        }>
                        {t('globle_质押理财')}
                      </div>
                      <div className="nav_more_content_min_item"
                        onClick={() =>
                          moverDom('earn')
                          // open(config.dappUrl + "bond")
                        }>
                        {t('globle_债券机制')}
                      </div>
                    </> : ''}
                <div className="nav_more_content_min_head" onClick={() => setFooterTab(footerTab == 3 ? 0 : 3)}>
                  <div className={["nav_more_content_min_head_down", footerTab == 3 ? 'nav_more_content_min_head_down_act' : ''].join(' ')} />
                  {t('globle_了解更多')}
                </div>
                {
                  footerTab == 3 ?
                    <>
                      <div className="nav_more_content_min_item" onClick={
                        () =>
                          window.open(i18n.language.toUpperCase() == 'ZH' ? config.documentUrlZH : config.documentUrl)
                      }>{t('globle_文档')}</div>
                      <div className="nav_more_content_min_item" onClick={() =>
                        window.open(
                          i18n.language == "EN" || i18n.language == "en" ?
                            'https://docsend.com/view/37bemcv39swy3y4i' : 'https://docsend.com/view/ytkdwutttk46tbmw'
                        )}>
                        {t('globle_演示文稿')}
                      </div>
                    </> : ''}

              </div>
          }
        </div>
        {
          below960 ?
            <div className="nav_more_box_btn">
              <div className="nav_more_box_btn1" onClick={() => open(config.dappUrl + "home")}>
                {t('globle_启动')}
              </div>
              <SelectLang />
            </div> : ''
        }
      </div>
    }
    <div className="nav-content">
      <div className="nav-content-left">
        <Link to="/">
          {/* <img className="logo" src={isScrollTop ? LogoMove : Logo} alt="" /> */}
          <img className="logo" src={Logo} alt="" />
        </Link>
        {
          below960 ?
            <div className="nav-content-left_m">
              <div className="launch_app_m" onClick={() => open(config.dappUrl + "home")}>
                {t('globle_启动')}</div>
              <img className="more" src={Menu} onClick={() => setMoreBox(true)} /></div> :

            ''
        }

      </div>
      <div className="nav-content-right">
        <div className="go_to_app"
          // onClick={() => window.open('https://test.nodedao.io/#/home')}
          onClick={() => open(config.dappUrl + "home")}
        >{t('globle_启动')}</div>
        <div className="nav_link_box">
          <div className="nav_link_item"
          // onClick={() => {
          //   setMoreBoxTab(!isMoreBox ? 1 : 0)
          //   setMoreBox(!isMoreBox)
          // }}
          // onMouseEnter={() => {
          //   setMoreBoxTab(1)
          //   setMoreBox(true)
          // }}


          >
            {t('globle_产品')} +
            <img src={NavOn} className="nav_link_on" style={{ width: "60px" }} />
            {/* {
              isMoreBox && moreBoxTab == 1 ?
                <img src={NavOn} className="nav_link_on" /> : ''
            } */}
            <div className="nav_link_item_c_box">

              <div className="nav_link_item_c_item_blb" />
              <div className="nav_link_item_c">

                <div className="nav_link_item_c_item"
                  onClick={() => open(config.dappUrl + "coinInsurancelist")}
                >
                  {t('globle_币价保险')}
                </div>
                <div className="nav_link_item_c_item"
                  onClick={() => open(config.dappUrl + "insurance")}>
                  {t('globle_合约保险')}
                </div>
                <div className="nav_link_item_c_item"
                  onClick={comingsoon}>
                  {t('globle_NFT保险')}{' '}
                  <span>
                    {t('globle_敬请期待')}
                  </span>
                </div>
                <div className="nav_link_item_c_item"
                  onClick={comingsoon}>
                  {t('globle_意外事件保险')}{' '}
                  <span>{t('globle_敬请期待')}</span>
                </div>
                <div className="nav_link_item_c_item"
                  onClick={comingsoon}>
                  {t('globle_传统金融保险')}{' '}
                  <span>{t('globle_敬请期待')}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="nav_link_item"
          // onClick={() => {
          //   setMoreBoxTab(!isMoreBox ? 2 : 0)
          //   setMoreBox(!isMoreBox)

          // }}
          // onMouseEnter={() => {
          //   setMoreBoxTab(2)
          //   setMoreBox(true)
          // }}
          >
            {t('globle_理财')} +
            <img src={NavOn} className="nav_link_on" style={{ width: "45px" }} />
            {/* {
              isMoreBox && moreBoxTab == 2 ?
                <img src={NavOn} className="nav_link_on" /> : ''
            } */}

            <div className="nav_link_item_c_box">

              <div className="nav_link_item_c_item_blb" />
              <div className="nav_link_item_c">

                <div className="nav_link_item_c_item"
                  onClick={() =>
                    moverDom('earn')
                    // open(config.dappUrl + "coinInsurance/pool")
                  }
                >
                  {t('globle_赚保险费')}
                </div>
                <div className="nav_link_item_c_item"
                  onClick={() =>
                    // open(config.dappUrl + "insurance/earn")
                    moverDom('earn')
                  }
                >
                  {t('globle_质押理财')}
                </div>
                <div className="nav_link_item_c_item"
                  onClick={() =>
                    // open(config.dappUrl + "bond")
                    moverDom('earn')
                  }
                >
                  {t('globle_债券机制')}
                </div>
              </div>
            </div>

          </div>
          <div className="nav_link_item" onClick={
            () =>
              window.open(i18n.language.toUpperCase() == 'ZH' ? config.documentUrlZH : config.documentUrl)
          }>
            {t('globle_文档')}
            <img src={NavOnOnly} className="nav_link_on" style={{ width: "40px" }} />
          </div>
          <div className="nav_link_item" onClick={() => moverDom('footer')}>
            {t('globle_加入我们')}
            <img src={NavOnOnly} className="nav_link_on" style={{ width: "45px" }} />
          </div>
        </div>
        <SelectLang isShadow={(isScrollTop || isMoreBox)}></SelectLang>
      </div>
    </div>
  </div>;
};

export default Nav;

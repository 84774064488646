import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMedia } from "react-use";
import "./index.scss";
import FLogo from '../../assets/img/footer_logo.png'
import FooterDot from '../../assets/img/footer_dot.png'
import { message } from 'antd'
import config from "../../config";
import { moverDom } from "../../utils";

const Footer = props => {
  const rout = useLocation();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const below960 = useMedia("(max-width: 960px)");
  const [index, setIndex] = useState(0)
  const [footerTab, setFooterTab] = useState(0)

  const comingsoon = () => {
    message.info(t('globle_敬请期待2'))
  }
  const navGoTo = h => {
    history.push(h);
    document.body.scrollTop = document.documentElement.scrollTop = 0
  };

  const open = (p) => {
    window.open(p)
  }

  return (
    <div className="footer_box" id="footer">
      {
        below960 ?
          <img src={FooterDot} className="footer_dot" /> : ''
      }
      <div className="footer">
        <div className="logo-box">
          <img src={FLogo} alt="" />
          {
            !below960 ?
              <>
                <div className="concact-list">
                  {t('globle_加入我们')}
                  <div className="concact-list_box">
                    <div className="concact-list_box_item_twitter" onClick={() => window.open('https://twitter.com/Marquee_fi')} />
                    <div className="concact-list_box_item_medium" onClick={() => window.open('https://medium.com/@MarqueeOfficial')} />
                    <div className="concact-list_box_item_discord" onClick={() => window.open('https://discord.com/invite/cp7akF4bg9')} />
                    <div className="concact-list_box_item_telegram" onClick={() => window.open('https://t.me/MarqueeGroup')} />
                  </div>
                </div>
                <p className="copyright">© 2022 Marquee. All Rights Reserved.</p></> : ''
          }

        </div>
        <div className="link-list">
          <div className={index === 1 ? 'link-item height-auto' : 'link-item'}>
            <p onClick={() => setFooterTab(footerTab == 1 ? 0 : 1)}>
              {
                below960 ? <div className={["footer_head_down", footerTab == 1 ? 'footer_head_down_act' : ''].join(' ')} /> : ''
              }
              {t('globle_产品')}
            </p>

            {
              footerTab == 1 || !below960 ?
                <>

                  <p
                    onClick={() => open(config.dappUrl + "coinInsurancelist")}
                  >
                    {t('globle_币价保险')}
                  </p>

                  <p
                    onClick={() => open(config.dappUrl + "insurance")}
                  >
                    {t('globle_合约保险')}
                  </p>
                  <p
                    onClick={comingsoon}
                  >
                    {t('globle_NFT保险')} {t('globle_敬请期待')}
                  </p>
                  <p
                    onClick={comingsoon}
                  >
                    {t('globle_意外事件保险')} {t('globle_敬请期待')}
                  </p>
                  <p
                    onClick={comingsoon}
                  >
                    {t('globle_传统金融保险')} {t('globle_敬请期待')}
                  </p>
                </> : ''
            }

          </div>
          <div className={index === 2 ? 'link-item height-auto' : 'link-item'}>
            <p onClick={() => setFooterTab(footerTab == 2 ? 0 : 2)}>

              {
                below960 ? <div className={["footer_head_down", footerTab == 2 ? 'footer_head_down_act' : ''].join(' ')} /> : ''
              }
              {t('globle_理财')}
            </p>
            {
              footerTab == 2 || !below960 ?
                <>

                  <p
                    onClick={() =>

                      moverDom('earn')
                      // open(config.dappUrl + "coinInsurance/pool")
                    }
                  >
                    {t('资金池')}
                  </p>
                  <p
                    onClick={() =>
                      // open(config.dappUrl + "insurance/earn")
                      moverDom('earn')
                    }
                  >
                    {t('质押')}
                  </p>
                  <p
                    onClick={() =>
                      // open(config.dappUrl + "bond")
                      moverDom('earn')
                    }
                  >
                    {t('债券')}
                  </p></> : ''
            }
          </div>
          <div className={index === 3 ? 'link-item height-auto link-item-m' : 'link-item link-item-m'}>
            <p onClick={() => {
              setFooterTab(footerTab == 3 ? 0 : 3)
            }
            } >

              {
                below960 ? <div className={["footer_head_down", footerTab == 3 ? 'footer_head_down_act' : ''].join(' ')} /> : ''
              }
              {t('globle_了解更多')}
            </p>
            {
              footerTab == 3 || !below960 ?
                <>
                  <p onClick={
                    () =>
                      window.open(i18n.language.toUpperCase() == 'ZH' ? config.documentUrlZH : config.documentUrl)
                  }>
                    {t('globle_文档')}
                  </p>
                  <p onClick={() => window.open(
                    i18n.language == "EN" || i18n.language == "en" ?
                      'https://bit.ly/45Q9Tq3' : 'https://bit.ly/45Q9Tq3')}>{t('globle_演示文稿')}
                  </p>

                  <p onClick={() => window.open(
                    i18n.language == "EN" || i18n.language == "en" ?
                      'https://marquee.fi/whitepaper.pdf' : 'https://marquee.fi/whitepaper.pdf')}>{t('footer_白皮书')}
                  </p>
                </> : ''
            }

          </div>
        </div>
        {
          below960 ?
            <>
              <div className="concact-list">
                {t('globle_加入我们')}

                <div className="concact-list_box">
                  <div className="concact-list_box_item_twitter" onClick={() => window.open('https://twitter.com/Marquee_fi')} />
                  <div className="concact-list_box_item_medium" onClick={() => window.open('https://medium.com/@MarqueeOfficial')} />
                  <div className="concact-list_box_item_discord" onClick={() => window.open('https://discord.com/invite/cp7akF4bg9')} />
                  <div className="concact-list_box_item_telegram" onClick={() => window.open('https://t.me/MarqueeGroup')} />
                </div>
              </div>
              <p className="copyright">© 2022 Marquee. All Rights Reserved.</p></> : ''
        }
      </div>
    </div>
  );
};

export default Footer;
